
//储存全局信息
export default ({
  state: {
    status: "matching",//matching表示匹配界面，playing表示对战界面
    is_match: false,//是否处在匹配状态
    socket: null,
    opponent_username: "",
    opponent_photo: "",
    //储存两个对战玩家信息
    a_id: 0,
    b_id: 0,
    map: null,
    gameObject: null,
    loser: "none"//all,A,B
  },

  getters: {
  },

  mutations: {
    updateSocket(state, socket) {
      state.socket = socket;
    },
    updateOpponent(state, opponent) {
      state.opponent_photo = opponent.photo;
      state.opponent_username = opponent.username;
    },
    updateStatus(state, status) {
      state.status = status;
    },
    updateIsMatch(state, is_match) {
      state.is_match = is_match;
    },
    updateGame(state, game) {
      state.a_id = game.a_id;
      state.b_id = game.b_id;
    },
    updateGameObject(state, gameObject) {
      state.gameObject = gameObject;
    },
    updateLoser(state, loser) {
      state.loser = loser;
    }
  },

  actions: {

  },

  modules: {
  },

})
