import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '../views/pk/PkIndexView.vue'
import RecordIndexView from '../views/record/RecordIndexView.vue'
import RecordContentView from "@/views/record/RecordContentView.vue"
import RankListIndexView from '../views/ranklist/RankListIndexView.vue'

import NotFound from '../views/error/NotFound.vue'
import UserAccountLoginViewVue from '@/views/uesr/account/UserAccountLoginView.vue'
import UserAccountRegisterViewVue from '@/views/uesr/account/UserAccountRegisterView.vue'
import store from '@/store/index.js'
import TestPage from '@/views/TestPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/pk/',
    meta: {
      requestAuth: true
    }
  },//重定向主页等等
  {
    path: '/pk/',
    name: 'pk_index',
    component: PkIndexView,
    meta: {
      requestAuth: true
    }
  },
  {
    path: '/record/',
    name: 'record_index',
    component: RecordIndexView,
    meta: {
      requestAuth: true
    }
  },
  {
    //可以加参数的路由
    path: '/record/:recordId/',
    name: 'record_content',
    component: RecordContentView,
    meta: {
      requestAuth: true
    }
  },
  {
    path: '/ranklist/',
    name: 'ranklist_index',
    component: RankListIndexView,
    meta: {
      requestAuth: true
    }
  },

  {
    path: '/404/',
    name: '404',
    component: NotFound,

  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404/'
  },//输入其他地址则重定向到404
  {
    path: '/user/account/login/',
    name: 'user_account_login',
    component: UserAccountLoginViewVue,
    meta: {
      requestAuth: false
    }
  },
  {
    path: '/user/account/register/',
    name: 'user_account_register',
    component: UserAccountRegisterViewVue,
    meta: {
      requestAuth: false
    }
  },
  {
    path: '/test/',
    name: 'test',
    component: TestPage,
    meta: {
      requestAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


//实现必须登录
//to:要去的页面
//from:从哪个页面来
//next:下一步操作
router.beforeEach((to, from, next) => {
  //判断是否已经登录，登录持久化，从localStorage中获取jwt_token
  const jwt_token = localStorage.getItem('jwt_token');
  if (jwt_token) {
    store.commit("updateToken", jwt_token);
    //通过获取用户信息是否成功来判断是否登录
    store.dispatch('getinfo', {
      success() {
        //获取用户信息成功后跳转到首页
        // router.push({
        //   name: 'home'
        // });
        store.state.user.is_login = true;
        next();
        store.commit("updatePullingInfo", false);
      },
      error() {
        store.commit("updatePullingInfo", false);
        //获取用户信息失败后跳转到登录页
        //这里不能用router.push，因为这里是在路由守卫中，会导致死循环
        //因为router会立马改变路由，又会触发路由守卫
        if (to.meta.requestAuth) {
          next({
            name: "user_account_login",
          })
        } else {
          next();
        }
      }
    })
  } else {
    store.commit("updatePullingInfo", false);
    if (to.meta.requestAuth) {
      next({
        name: "user_account_login",
      })
    } else {
      next();
    }
  }
})

export default router
