<template>
  <ContenField>
    <!-- grid分区，justify-content-md-center居中 -->
    <div class="row justify-content-md-center">
      <div class="col-3">
        <!-- 阻止掉默认行为 -->
        <form @submit.prevent="register">
          <div class="form-floating mb-2">
            <input
              v-model="username"
              type="text"
              class="form-control"
              id="username"
              placeholder="请输入用户名"
            />
            <label for="floatingInput">账号</label>
          </div>
          <div class="form-floating mb-2">
            <input
              v-model="password"
              type="password"
              class="form-control"
              id="password"
              placeholder="请输入密码"
            />
            <label for="floatingPassword">密码</label>
          </div>
          <div class="form-floating mb-2">
            <input
              v-model="confirmPassword"
              type="password"
              class="form-control"
              id="confirmPassword"
              placeholder="请再次输入密码"
            />
            <label for="floatingPassword">请确认密码</label>
          </div>
          <div class="error-message">{{ error_message }}</div>
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">注册</button>
          </div>
        </form>
      </div>
    </div>
  </ContenField>
</template>

<script>
import ContenField from "@/components/ContenField.vue"; //引入模版组件
import { ref } from "vue";
import router from "@/router/index.js";
import $ from "jquery";

export default {
  components: {
    ContenField
  },

  setup() {
    let username = ref("");
    let password = ref("");
    let confirmPassword = ref("");
    let error_message = ref("");

    //为什么注册函数不放在vuex里面，因为不会影响到state里面的数据。
    const register = () => {
      $.ajax({
        url: "https://jinzhihang.xyz/api/user/account/register/",
        type: "post",
        data: {
          username: username.value,
          password: password.value,
          confirmPassword: confirmPassword.value
        },
        //ajax的固定函数失败和成功调用的函数
        //resp是后端返回的数据
        success(resp) {
          if (resp.error_message === "注册成功") {
            router.push({
              name: "user_account_login"
            });
          } else {
            error_message.value = resp.error_message;
          }
        }
      });
    };

    return {
      username,
      password,
      confirmPassword,
      error_message,
      register
    };
  }
};
</script>

<style scoped>
div.error-message {
  color: red;
}
</style>