<template>
  <ContenField v-if="!$store.state.user.pulling_info">
    <!-- grid分区，justify-content-md-center居中 -->
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-3">
        <!-- 阻止掉默认行为 -->
        <form @submit.prevent="login">
          <div class="form-floating mb-2 ">
            <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
            <label for="floatingInput">账号</label>
          </div>
          <div class="form-floating mb-2">
            <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
            <label for="floatingPassword">密码</label>
          </div>
          <div class="error-message">{{ error_message }}</div>
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit">登录</button>
          </div>
        </form>
      </div>
    </div>
  </ContenField>
</template>

<script>
import ContenField from '@/components/ContenField.vue' //引入模版组件
import { ref } from 'vue'
import { useStore } from 'vuex';
import router from '@/router/index.js'

export default {
  components: {
    ContenField
  },

  setup() {
    const store = useStore();
    let username = ref('');
    let password = ref('');
    let error_message = ref('');

    

    //触发函数
    const login = () => {
      error_message.value = '';
      //调用action需要用到dispatch
      store.dispatch('login', {
        username: username.value,
        password: password.value,

        success() {
          store.dispatch('getinfo', {
            success() {
              //获取用户信息成功后跳转到首页
              router.push({
                name: 'home'
              });
            }
          })
        },

        error() {
          error_message.value = "用户名或密码错误";
        }
      })
    }

    return {
      username,
      password,
      error_message,
      login,
    }
  }
}
</script>

<style scoped>
div.error-message {

  color: red;

}
</style>