<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <!-- 导航栏的宽度 -->
      <!-- <a class="navbar-brand" href="/">首页</a>  //每次点击页面都会刷新-->
      <router-link class="navbar-brand" :to="{ name: 'home' }">五子棋</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <!-- 绑定route_name 判定用户当前在哪个页面就可以高亮-->
            <router-link
              :class="route_name == 'pk_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'pk_index' }"
            >对战</router-link>
          </li>
          <li class="nav-item">
            <router-link
              :class="route_name == 'record_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'record_index' }"
            >对局列表</router-link>
          </li>
          <li class="nav-item">
            <router-link
              :class="route_name == 'ranklist_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'ranklist_index' }"
            >排行榜</router-link>
          </li>
        </ul>

        <!-- //判断登录状态 -->
        <!-- <ul class="navbar-nav" v-if="$store.state.user.is_login"> -->
        <ul class="navbar-nav" v-if="$store.state.user.is_login">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >{{ $store.state.user.username }}</a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item logout" @click="logout">退出</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav" v-else-if="!$store.state.user.pulling_info">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'user_account_login' }" role="button">登录</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'user_account_register' }" role="button">注册</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";
import router from "@/router/index.js";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    let route_name = computed(() => route.name);

    const logout = () => {
      store.dispatch("logout", {
        success() {
          //退出成功后刷新
          router.go(0);
        }
      });
    };
    return {
      route_name,
      logout
    };
  }
};
</script>

<style scoped>
.logout {
  cursor: pointer;
}
</style>