<template>
  <div class="result-board">
    <div class="result-board-text" v-if="$store.state.pk.loser == $store.state.user.id">你赢了</div>
    <div class="result-board-text" v-else>你输了</div>
    <div class="result-board-btn">
      <div class="d-grid gap-2 col-5 mx-auto">
        <button type="button" class="btn btn-warning btn-lg" @click="restart()">
          <div class="btn-font">重新匹配</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const restart = () => {
      store.commit("updateStatus", "matching");
      store.commit("updateLoser", "none");
      store.commit("updateGame", {
        a_id: null,
        b_id: null
      });
      store.commit("updateOpponent", {
        username: "等待匹配",
        photo:
          "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png"
      });
    };
    return {
      restart
    };
  }
};
</script>

<style>
.result-board {
  height: 30vh;
  width: 30vw;
  background-color: rgb(50, 50, 50, 0.5);
  position: absolute;
  top: 30vh;
  left: 35vw;
}

.result-board-text {
  text-align: center;
  color: white;
  font-size: 5vh;
  font-weight: 600;
  font-style: italic;
}

.result-board-btn {
  padding-top: 10vh;
}
.btn-font {
  font-size: 3vh;
}
</style>