<template>
  <ContenField>
    <table class="table table-striped table-hover" style="text-align: center;">
      <thead>
        <tr>
          <th>黑子</th>
          <th>白子</th>
          <th>对战结果</th>
          <th>对战时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in records" :key="record.record.id">
          <td>
            <img :src="record.a_photo" alt="加载失败" class="record-user-photo" />
            &nbsp;
            <!-- 空格 -->
            <span class="record-user-username">{{ record.a_username }}</span>
          </td>
          <td>
            <img :src="record.b_photo" alt="加载失败" class="record-user-photo" />
            &nbsp;
            <span class="record-user-username">{{ record.b_username }}</span>
          </td>
          <td>{{ record.result }}</td>
          <td>{{record.record.createtime}}</td>
          <td>
            <button
              type="button"
              class="btn btn-primary"
              @click="open_record_content(record.record.id)"
            >查看回放</button>
            <button
              type="button"
              class="btn btn-primary"
              @click="open_record_photo(record.record.id)"
            >查看快照</button>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination" style="float:right">
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Previous" @click="click_page(-2)">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          :class="'page-item ' + page.is_active"
          v-for="page in pages"
          :key="page.number"
          @click="click_page(page.number)"
        >
          <a class="page-link" href="#">{{page.number}}</a>
        </li>
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Next" @click="click_page(-1)">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </ContenField>
</template>

<script>
import ContenField from "@/components/ContenField.vue"; //引入模版组件
import $ from "jquery";
import { useStore } from "vuex";
import { ref } from "vue";
import router from "@/router";

export default {
  components: {
    ContenField
  },
  setup() {
    const store = useStore();
    let currentPage = 1;
    let records = ref([]);
    let total_records = 0;
    let pages = ref([]);

    const click_page = page => {
      if (page === -2) {
        page = currentPage - 1;
      } else if (page === -1) {
        page = currentPage + 1;
      }

      let max_page = parseInt(Math.ceil(total_records / 10));
      if (page >= 1 && page <= max_page) {
        pull_page(page);
      }
    };

    const updatePages = () => {
      let max_page = parseInt(Math.ceil(total_records / 10));
      let new_pages = [];
      for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        if (i >= 1 && i <= max_page) {
          new_pages.push({
            number: i,
            is_active: i === currentPage ? "active" : ""
          });
        }
      }
      pages.value = new_pages;
    };

    const pull_page = page => {
      currentPage = page;
      $.ajax({
        url: "https://jinzhihang.xyz/api/record/getlist/",
        data: {
          page: page
        },
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          records.value = resp.records;
          total_records = resp.records_count;
          updatePages();
        },
        error(resp) {
          console.log(resp);
        }
      });
    };

    pull_page(currentPage);

    const stringTo2D = map => {
      let g = [];
      for (let i = 0, k = 0; i < 15; i++) {
        let line = [];
        for (let j = 0; j < 15; j++, k++) {
          if (map[k] === ".") line.push(".");
          else if (map[k] === "A") line.push("A");
          else if (map[k] === "B") line.push("B");
        }
        g.push(line);
      }
      return g;
    };

    const open_record_content = recordId => {
      store.commit("updateIsRecordPhoto", false);
      for (const record of records.value) {
        if (record.record.id === recordId) {
          store.commit("updateIsRecord", true);
          //console.log(record);
          store.commit("updateRecordGame", {
            map: stringTo2D(record.record.map),
            a_id: record.record.aid,
            b_id: record.record.bid
          });
          store.commit("updateSteps", {
            a_steps: record.record.asteps,
            b_steps: record.record.bsteps
          });
          store.commit("updateRecordLoser", record.record.loser);
          router.push({
            name: "record_content",
            params: {
              recordId
            }
          });
          break;
        }
      }
    };

    const open_record_photo = recordId => {
      for (const record of records.value) {
        if (record.record.id === recordId) {
          store.commit("updateIsRecord", true);
          //console.log(record);
          store.commit("updateIsRecordPhoto", true);

          store.commit("updateRecordGame", {
            map: stringTo2D(record.record.map),
            a_id: record.record.aid,
            b_id: record.record.bid
          });
          store.commit("updateRecordLoser", record.record.loser);
          router.push({
            name: "record_content",
            params: {
              recordId
            }
          });
          break;
        }
      }
    };

    return {
      pull_page,
      total_records,
      records,
      open_record_content,
      open_record_photo,
      pages,
      click_page
    };
  }
};
</script>

<style scoped>
img.record-user-photo {
  width: 5vh;
  border-radius: 50%;
}
</style>