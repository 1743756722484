<template>
  <div>
    <PlayGround />
    <div class="d-grid gap-2 col-4 mx-auto">
      <button class="btn btn-primary" type="button" @click="return_record_list">返回对局列表</button>
    </div>
  </div>
</template>

<script>
import PlayGround from "@/components/PlayGround.vue";
import router from "@/router";

export default {
  components: {
    PlayGround
  },
  setup() {
    const return_record_list = () => {
      router.push({ name: "record_index" });
    };
    return {
      return_record_list
    };
  }
};
</script>

<style scoped></style>