<template>
    <div class="d-flex flex-column min-vh-100">
    <div class="container-fluid flex-grow-1">
      <NavBar />
      <router-view />
    </div>
    <div class="foot"><CopyRight /></div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import CopyRight from "./components/CopyRight.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

export default {
  components: {
    NavBar,
    CopyRight
  },

  setup() {}
};
</script>

<style>

body {
  /* background-image: url("./assets/images/paper-753508.jpg"); */
  background-color: #F2F2F2;
  
}
.foot{
  margin-top: 5vh;
  padding-left: 12px;
  padding-right: 12px;
}
</style>