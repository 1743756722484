import { GameObject } from "./GameObject";

export class ChessPiece extends GameObject {
  constructor(row, col, cellSize, color, gamemap) {
    super();
    this.row = row;
    this.col = col;
    this.cellSize = cellSize;

    this.color = color;
    this.gamemap = gamemap;
    this.x = col * cellSize + cellSize / 2; // 棋子的水平中心位置
    this.y = 0; // 棋子的初始垂直位置（从顶部开始）
    this.targetY = row * cellSize + cellSize / 2; // 棋子的目标垂直位置
    this.radius = cellSize / 2.5; // 棋子的半径
    this.initialRadius = cellSize / 1.5; // 棋子的初始半径
    this.speed = 0.5; // 棋子的下落速度
  }


  start() {

  }

  // 每一帧都会调用此函数来更新对象状态
  update() {
    this.x = this.col * this.cellSize + this.cellSize / 2;
    this.targetY = this.row * this.cellSize + this.cellSize / 2;
    this.radius = this.cellSize / 2.5;
    // 如果棋子还没有到达目标位置，则继续向下移动
    if (this.y < this.targetY) {
      this.y += this.speed * this.timedelta; // 根据速度和时间间隔更新位置
      this.radius = this.initialRadius - (this.initialRadius - this.cellSize / 2.5) * (this.y / this.targetY); // 缩小棋子的半径
      // 如果超出目标位置，则调整到目标位置
      if (this.y > this.targetY) {
        this.y = this.targetY;
        this.radius = this.cellSize / 2.5; // 确保到达目标位置时半径为最终大小
      }
    }
    this.render();
  }

  render() {
    const ctx = this.gamemap.ctx;

    // 绘制阴影
    ctx.beginPath();
    ctx.arc(this.x, this.targetY, this.radius, 0, 2 * Math.PI);
    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
    ctx.fill();


    ctx.beginPath();
    ctx.fillStyle = this.color;
    ctx.arc(this.x, this.targetY, this.radius, 0, Math.PI * 2);
    ctx.fill();

    // 绘制高光
    const gradient = ctx.createRadialGradient(
      this.x - this.radius / 3,
      this.targetY - this.radius / 3,
      this.radius / 10,
      this.x,
      this.targetY,
      this.radius
    );

    gradient.addColorStop(0, 'rgba(255, 255, 255, 0.7)');
    gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0)');

    ctx.fillStyle = gradient;
    ctx.fill();

  }

  // 对象销毁之前调用此函数
  on_destory() {
    // 可以在这里清理资源或做其他收尾工作
  }
}