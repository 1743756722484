<template>
  <div>
    <PlayGround v-if="$store.state.pk.status === 'playing'" />
    <MatchGround v-if="$store.state.pk.status === 'matching'" />
    <ResultBoard v-if="$store.state.pk.loser !== 'none' && $store.state.pk.status === 'playing'" />

    <div v-if="$store.state.pk.status === 'playing'" class="timer">本回合剩余时间: {{ timeRemaining }} 秒</div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import PlayGround from "@/components/PlayGround.vue";
import ResultBoard from "@/components/ResultBoard.vue";
import MatchGround from "@/components/MatchGround.vue";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  components: {
    PlayGround,
    MatchGround,
    ResultBoard
  },
  setup() {
    const store = useStore();
    const socketUrl = `wss://jinzhihang.xyz/websocket/${store.state.user.token}/`;

    store.commit("updateStatus", "matching");
    //计时器逻辑
    const timeRemaining = ref(60);
    let timer = null;

    let socket = null;

    const startTurn = () => {
      clearInterval(timer);
      timeRemaining.value = 60;

      timer = setInterval(() => {
        timeRemaining.value--;
        if (timeRemaining.value <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    };

    onMounted(() => {
      store.commit("updateLoser", "none");
      store.commit("updateIsRecord", false);

      store.commit("updateOpponent", {
        username: "等待匹配",
        photo:
          "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png"
      });
      socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        //console.log("connected!");
        store.commit("updateSocket", socket);
      };

      socket.onmessage = message => {
        const data = JSON.parse(message.data);

        if (data.event === "start-matching") {
          store.commit("updateOpponent", {
            username: data.opponent_username,
            photo: data.opponent_photo
          });
          setTimeout(() => {
            store.commit("updateStatus", "playing");
            startTurn();
          }, 2000);
          store.commit("updateGame", data.game);
        } else if (data.event === "add_piece") {
          const game = store.state.pk.gameObject;
          game.add_piece(data.row, data.col, data.player_id);
        } else if (data.event === "result") {
          clearInterval(timer);
          store.commit("updateLoser", data.winner);
        } else if (data.event === "switch_turn") {
          startTurn();
        }
      };
      socket.onclose = () => {
        //console.log("disconnected!");
        store.commit("updateStatus", "matching");
      };
    });
    onUnmounted(() => {
      socket.close();
      clearInterval(timer);
      store.commit("updateStatus", "matching");
    });

    return {
      timeRemaining
    };
  }
};
</script>

<style scoped>
.timer {
  text-align: center;
  color: red;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}
</style>