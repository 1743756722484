import $ from "jquery";

//储存全局信息
export default ({
  state: {
    id: "",
    username: "",
    photo: "",
    token: "",
    is_login: false,
    pulling_info: true,//是否在进行用户信息拉取，如果在，则不再展示登录页面
  },

  getters: {
  },

  //用来修改数据
  mutations: {
    updateUser(state, user) {
      state.id = user.id;
      state.username = user.username;
      state.photo = user.photo;
      state.is_login = user.is_login;
    },
    updateToken(state, token) {
      state.token = token;
    },
    logout(state) {
      state.username = "";
      state.photo = "";
      state.id = "";
      state.token = "";
      state.is_login = false;

    },
    updatePullingInfo(state, pulling_info) {
      state.pulling_info = pulling_info;
    }
  },

  actions: {
    //修改state的函数可以在这里写。辅助函数
    login(context, data) {
      $.ajax({
        url: "https://jinzhihang.xyz/api/user/account/token/",
        type: "post",
        data: {
          username: data.username,
          password: data.password,
        },
        //后端检测成功后，返回token和error_message（自己定义的
        success(resp) {
          //在actions里面调用mutations里面的函数需要用commit+字符串
          //resp是后端返回的数据，是自己定义的
          if (resp.error_message == "success") {
            //将token存入localStorage
            localStorage.setItem("jwt_token", resp.token);
            context.commit("updateToken", resp.token);
            //回调函数,告诉调用者成功了
            data.success(resp);
          } else {
            data.error(resp);
          }//这个else执行不到
        },
        //将封装好的authenticationToken进行验证，判断是否合法，非法会自动报异常
        error(err) {
          data.error(err);
        }
      })
    },

    //向后端请求用户信息，辅助函数
    getinfo(context, data) {
      $.ajax({
        url: "https://jinzhihang.xyz/api/user/account/info/",
        type: "get",
        //验证token
        headers: {
          Authorization: "Bearer " + context.state.token,
        },

        success(resp) {
          if (resp.error_message == "success") {
            context.commit("updateUser", {
              //...代表解构出所有的属性
              ...resp,
              is_login: true,
            });
            //回调函数
            data.success(resp);
          } else {
            data.error(resp);
          }
        },
        error(resp) {
          data.error(resp);
        }

      })
    },

    logout(context, callbacks) {
      localStorage.removeItem("jwt_token");
      context.commit("logout");
      callbacks.success();
    },


  },

  modules: {
  },

})
