<template>
  <ContenField>
    <table class="table table-striped table-hover" style="text-align: center;">
      <thead>
        <tr>
          <th>玩家</th>
          <th>天梯分</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>
            <img :src="user.photo" alt="加载失败" class="record-user-photo" />
            &nbsp;
            <!-- 空格 -->
            <span class="record-user-username">{{ user.username }}</span>
          </td>
          <td>{{ user.rating }}</td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination" style="float:right">
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Previous" @click="click_page(-2)">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          :class="'page-item ' + page.is_active"
          v-for="page in pages"
          :key="page.number"
          @click="click_page(page.number)"
        >
          <a class="page-link" href="#">{{page.number}}</a>
        </li>
        <li class="page-item">
          <a class="page-link" href="#" aria-label="Next" @click="click_page(-1)">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </ContenField>
</template>

<script>
import ContenField from "@/components/ContenField.vue"; //引入模版组件
import $ from "jquery";
import { useStore } from "vuex";
import { ref } from "vue";

export default {
  components: {
    ContenField
  },
  setup() {
    const store = useStore();
    let currentPage = 1;
    let users = ref([]);
    let total_users = 0;
    let pages = ref([]);

    const click_page = page => {
      if (page === -2) {
        page = currentPage - 1;
      } else if (page === -1) {
        page = currentPage + 1;
      }

      let max_page = parseInt(Math.ceil(total_users / 10));
      if (page >= 1 && page <= max_page) {
        pull_page(page);
      }
    };

    const updatePages = () => {
      let max_page = parseInt(Math.ceil(total_users / 10));
      let new_pages = [];
      for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        if (i >= 1 && i <= max_page) {
          new_pages.push({
            number: i,
            is_active: i === currentPage ? "active" : ""
          });
        }
      }
      pages.value = new_pages;
    };

    const pull_page = page => {
      currentPage = page;
      $.ajax({
        url: "https://jinzhihang.xyz/api/ranklist/getlist/",
        data: {
          page: page
        },
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token
        },
        success(resp) {
          users.value = resp.users;
          total_users = resp.users_count;
          updatePages();
        },
        error(resp) {
          console.log(resp);
        }
      });
    };

    pull_page(currentPage);

    return {
      total_users,
      users,
      pages,
      click_page
    };
  }
};
</script>

<style scoped>
img.record-user-photo {
  width: 5vh;
  border-radius: 50%;
}
</style>