
//储存全局信息
export default ({
  state: {
    is_record: false,
    is_record_photo: false,
    record_a_id: "",
    a_steps: "",
    b_steps: "",
    record_b_id: "",
    record_map: null,
    record_loser: ""
  },

  getters: {
  },

  mutations: {
    updateIsRecord(state, is_record) {
      state.is_record = is_record;
    },
    updateRecordGame(state, record_game) {
      state.record_a_id = record_game.a_id;
      state.record_b_id = record_game.b_id;
      state.record_map = record_game.map;
    },
    updateIsRecordPhoto(state, is_record_photo) {
      state.is_record_photo = is_record_photo;
    },
    updateSteps(state, data) {
      state.a_steps = data.a_steps;
      state.b_steps = data.b_steps
    },
    updateRecordLoser(state, record_loser) {
      state.record_loser = record_loser;
    }
  },

  actions: {

  },

  modules: {
  },

})
