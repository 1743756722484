<template>
    <footer class="container-fluid text-center copyright mt-auto">
      <div class="row">
        <div class="col">
          <div>

            {{`Copyright© ${year} ${author} ` }}
            <img class="banquan" src="../assets/images/banquan.svg" />
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <a href="http://beian.miit.gov.cn/" target="_blank">
            <img class="banquan" src="../assets/images/Beianicon.svg" />
          </a>
          <a href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a>

        
          <a href="http://www.beian.gov.cn/portal/index.do" target="_blank">
            <img class="banquan" src="../assets/images/gongan.png" />
          </a>
          <a href="http://www.beian.gov.cn/portal/index.do" target="_blank">{{ gongan }}</a>
        </div>
      </div>
    </footer>
</template>

<script>
let year = new Date().getFullYear();
let author = "Im_Ok";
let record = "鲁ICP备2024097277号"; // 备案号
let gongan = "鲁公网安备37011202002227号";
export default {
  setup() {
    return {
      year,
      author,
      record,
      gongan,
    };
  }
};
</script>

<style scoped>
.copyright {
  background-color: #E5E8EB;
  /* padding: 10px 0; */
  padding-top: 5vh;
  padding-bottom: 5vh;
  color: gray;
}

.banquan{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
a{
  color: gray;
  text-decoration: none;
}

a:hover{
  color: #0099FF;
}
hr {
    width: 80%;
    margin: 10px auto;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #0099FF,
      rgba(0, 0, 0, 0)
    );
}
</style>