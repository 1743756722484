<template>
  <ContenField>
    404NotFound
  </ContenField>

</template>

<script>
import ContenField from '@/components/ContenField.vue' //引入模版组件

export default {
  components: {
    ContenField
  }
}
</script>

<style scoped>

</style>

