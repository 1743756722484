<template>
  <div  class="overlay">
    <div class="matchground">
      <div class="row">
        <div class="col-6">
          <div class="user-photo">
            <img :src="$store.state.user.photo" />
          </div>
          <div class="user-username">{{ $store.state.user.username }}</div>
        </div>
        <div class="col-6">
          <div class="user-photo">
            <img :src="$store.state.pk.opponent_photo" />
          </div>
          <div class="user-username">{{ $store.state.pk.opponent_username }}</div>
        </div>
      </div>
      <div
        class="col-12 user-side"
        v-if="$store.state.pk.status==='matching' &&  $store.state.user.id == $store.state.pk.a_id"
      >执黑子</div>
      <div
        class="col-12 user-side"
        v-if="$store.state.pk.status==='matching' && $store.state.user.id == $store.state.pk.b_id"
      >执白子</div>
      <div class="col-12">
        <div class="d-grid gap-2 col-4 mx-auto" style="padding-top:15vh">
          <button type="button" class="btn btn-warning btn-lg" @click="click_match_btn()">
            <div class="btn-font">{{ match_btn_info }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    let match_btn_info = ref("开始匹配");
    let select_bot = ref("-1");

    const click_match_btn = () => {
      if (match_btn_info.value === "开始匹配") {
        match_btn_info.value = "取消"; 
        store.state.pk.socket.send(
          JSON.stringify({
            event: "start-matching",
            bot_id: select_bot.value
          })
        );

        document.querySelector('.overlay').classList.add('active');
      } else {
        match_btn_info.value = "开始匹配";
        store.state.pk.socket.send(
          JSON.stringify({
            event: "stop-matching"
          })
        );

        document.querySelector('.overlay').classList.remove('active');
      }
    };

    return {
      match_btn_info,
      click_match_btn,
      select_bot
    };
  }
};
</script>

<style scoped>
.matchground {
  width: 60vw;
  height: 70vh;
  top: 15vh;
  left: 20vw;
  margin: 40px auto;
  background-color: rgb(50, 50, 50, 0.5);
  transition: all 0.5s ease;
}


.overlay {
  width: 60vw;
  height: 70vh;
  top: 15vh;
  left: 20vw;
  margin: 40px auto;
  
  transition: all 0.5s ease;
}

.overlay.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  z-index: 1000;
}

.overlay.active .matchground  {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  z-index: 1000;
}

.user-photo {
  text-align: center;
  padding-top: 8vh;
}
.user-photo > img {
  border-radius: 50%;
  width: 20vh;
}
.user-username {
  text-align: center;
  font-size: 4vh;
  font-weight: 600;
  color: white;
  padding-top: 2vh;
}
.btn-font {
  font-size: 3vh;
  font-weight: 1000;
}
.user-select-bot {
  padding-top: 15vh;
}

.user-select-bot > select {
  width: 60%;
  margin: 0 auto;
}
.user-side {
  text-align: center;
  color: rgb(27, 41, 53);
  font-size: 30px;
  font-weight: bold;
}



</style>
