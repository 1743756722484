<template>
  <div class="container content_field">
    <!-- 一个自适应容器 -->
    <div class="card">
      <div class="card-body">
        <slot></slot>      
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
div.content_field{
  margin-top: 20px;
}
</style>