import { GameObject } from "./GameObject";
import { ChessPiece } from "./ChessPiece";


export class GameMap extends GameObject {
  constructor(ctx, parent, store) {
    super();

    this.ctx = ctx;
    this.parent = parent;
    this.store = store;
    this.L = 0;//一个格子的绝对距离

    this.rows = 15;//行数
    this.cols = 15;//列数

    this.pieces = [];//棋子数组
    this.hoverRow = null;
    this.hoverCol = null;

    //上一步对手落子的位置
    this.lastPieceRow = null;
    this.lastPieceCol = null;
  }


  add_listening_events() {
    //如果是实时对战，需要监听键盘事件
    //如果是回放，不需要监听键盘事件
    if (this.store.state.record.is_record) {
      if (this.store.state.record.is_record_photo) {
        //根据棋盘更新棋子，'.'表示空白，'A'表示黑子，'B'表示白子

        const map = this.store.state.record.record_map;
        for (let i = 0; i < 15; i++) {
          for (let j = 0; j < 15; j++) {
            let piece = null;
            if (map[i][j] == 'A') {
              piece = new ChessPiece(i, j, this.L, 'black', this);
            } else if (map[i][j] == 'B') {
              piece = new ChessPiece(i, j, this.L, '#D1D1D1', this);
            }
            if (piece != null) {
              this.pieces.push(piece);
            }
          }
        }
      } else if (!this.store.state.record.is_record_photo) {
        //每1s走一步，复现棋局
        let k = 0;

        //倒转一下，因为后端传过来的是倒序的
        // let a_steps1 = this.store.state.record.a_steps.split('').reverse().join('').toString();
        //let b_steps1 = this.store.state.record.b_steps.split('').reverse().join('').toString();
        let a_steps = this.store.state.record.a_steps
        let b_steps = this.store.state.record.b_steps

        let length = a_steps.length + b_steps.length;
        //const winner = this.store.state.record.record_loser;
        //setInterval函数的作用是每隔一段时间执行一次函数
        let flag = 0;//flag为0表示黑子走，flag为1表示白子走
        let i = 0, j = 0;
        const interval_id = setInterval(() => {
          if (k >= (length / 4)) {
            //停止setInterval函数
            clearInterval(interval_id);
          } else {
            //更新棋子，a_steps表示黑子的落子位置，b_steps表示白子的落子位置，每次交替从a_steps和b_steps中取出四位数，前两位表示行，后两位表示列
            if (flag % 2 == 0) {
              // console.log(a_steps);
              // console.log(a_steps % 100);
              const a_row = parseInt(a_steps[j] + a_steps[j + 1]);
              j += 2;
              const a_col = parseInt(a_steps[j] + a_steps[j + 1]);
              j += 2;
              this.add_piece(a_row, a_col, this.store.state.record.record_a_id, this);
            } else {
              const b_row = parseInt(b_steps[i] + b_steps[i + 1]);
              i += 2;
              const b_col = parseInt(b_steps[i] + b_steps[i + 1]);
              i += 2;

              this.add_piece(b_row, b_col, this.store.state.record.record_b_id, this);
            }
            flag++;
            k++;
          }
        }, 1000)
      }

    } else {
      this.ctx.canvas.addEventListener("click", e => {
        const { offsetX, offsetY } = e;
        const row = Math.floor(offsetY / this.L);
        const col = Math.floor(offsetX / this.L);

        // 检查是否已经有棋子在该位置
        if (this.pieces.some(piece => piece.row === row && piece.col === col)) {
          return; // 位置已被占用，忽略点击
        }

        //向后端发送落子信息
        this.store.state.pk.socket.send(JSON.stringify({
          event: "add_piece",
          row: row,
          col: col,
          player_id: this.store.state.user.id
        }));


      });

      this.ctx.canvas.addEventListener('mousemove', (event) => {
        const { offsetX, offsetY } = event;
        this.hoverRow = Math.floor(offsetY / this.L);
        this.hoverCol = Math.floor(offsetX / this.L);
      });

      this.ctx.canvas.addEventListener('mouseleave', () => {
        this.hoverRow = null;
        this.hoverCol = null;
      });
    }
  }

  add_piece(row, col, playerId, gamemap = this) {
    let color = 'black';
    if (this.store.state.record.is_record) {
      if (playerId == this.store.state.record.record_a_id) {
        color = 'black';
      } else if (playerId == this.store.state.record.record_b_id) {
        color = '#D1D1D1';//浅灰色
      }
    } else {
      if (playerId == this.store.state.pk.a_id) {
        color = 'black';
      } else if (playerId == this.store.state.pk.b_id) {
        color = '#D1D1D1';//浅灰色
      }
    }

    const piece = new ChessPiece(row, col, this.L, color, gamemap);
    this.pieces.push(piece);

    this.add_last_piece_border(row, col);
  }

  //给对手上一步落子的位置绘制提示边框
  add_last_piece_border(row, col) {
    this.lastPieceRow = row;
    this.lastPieceCol = col;
  }



  start() {
    this.add_listening_events();
  }

  update_size() {
    //由于浮点数的问题，格子之间会有一段空白，取成整像素，即可解决
    this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
    this.ctx.canvas.width = this.L * this.cols;
    this.ctx.canvas.height = this.L * this.rows;
  }


  update() {
    this.update_size();
    this.render();
  }

  //绘制提示边框
  draw_hover_border(row, col) {
    const x = col * this.L;
    const y = row * this.L;


    const borderSize = 3;
    const borderLength = 10;

    this.ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
    this.ctx.lineWidth = borderSize;

    // Top-left corner
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x + borderLength, y);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x, y + borderLength);
    this.ctx.stroke();

    // Top-right corner
    this.ctx.beginPath();
    this.ctx.moveTo(x + this.L, y);
    this.ctx.lineTo(x + this.L - borderLength, y);
    this.ctx.moveTo(x + this.L, y);
    this.ctx.lineTo(x + this.L, y + borderLength);
    this.ctx.stroke();

    // Bottom-left corner
    this.ctx.beginPath();
    this.ctx.moveTo(x, y + this.L);
    this.ctx.lineTo(x + borderLength, y + this.L);
    this.ctx.moveTo(x, y + this.L);
    this.ctx.lineTo(x, y + this.L - borderLength);
    this.ctx.stroke();

    // Bottom-right corner
    this.ctx.beginPath();
    this.ctx.moveTo(x + this.L, y + this.L);
    this.ctx.lineTo(x + this.L - borderLength, y + this.L);
    this.ctx.moveTo(x + this.L, y + this.L);
    this.ctx.lineTo(x + this.L, y + this.L - borderLength);
    this.ctx.stroke();
  }

  //绘制对手上一步落子的位置
  draw_last_piece_border() {
    if (this.lastPieceRow === null || this.lastPieceCol === null) {
      return;
    }

    const x = this.lastPieceCol * this.L;
    const y = this.lastPieceRow * this.L;
    const borderSize = 1;
    const borderLength = 10;

    this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
    this.ctx.lineWidth = borderSize;

    // Top-left corner
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x + borderLength, y);
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(x, y + borderLength);
    this.ctx.stroke();

    // Top-right corner
    this.ctx.beginPath();
    this.ctx.moveTo(x + this.L, y);
    this.ctx.lineTo(x + this.L - borderLength, y);
    this.ctx.moveTo(x + this.L, y);
    this.ctx.lineTo(x + this.L, y + borderLength);
    this.ctx.stroke();

    // Bottom-left corner
    this.ctx.beginPath();
    this.ctx.moveTo(x, y + this.L);
    this.ctx.lineTo(x + borderLength, y + this.L);
    this.ctx.moveTo(x, y + this.L);
    this.ctx.lineTo(x, y + this.L - borderLength);
    this.ctx.stroke();

    // Bottom-right corner
    this.ctx.beginPath();
    this.ctx.moveTo(x + this.L, y + this.L);
    this.ctx.lineTo(x + this.L - borderLength, y + this.L);
    this.ctx.moveTo(x + this.L, y + this.L);
    this.ctx.lineTo(x + this.L, y + this.L - borderLength);
    this.ctx.stroke();

  }

  //渲染函数
  render() {

    //绘制棋盘
    const color_even = "#F0D9B5";
    const color_odd = "#B58863";

    for (let r = 0; r < this.rows; r++) {
      for (let c = 0; c < this.cols; c++) {
        this.ctx.fillStyle = (r + c) % 2 === 0 ? color_even : color_odd;
        this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
      }
    }


    // 绘制提示边框
    if (this.hoverRow !== null && this.hoverCol !== null) {
      this.draw_hover_border(this.hoverRow, this.hoverCol);

    }



    //绘制棋子
    for (const piece of this.pieces) {
      //更新棋子的大小和位置
      piece.cellSize = this.L;
      piece.render();
    }

    // 绘制上一步对手落子的位置
    if (this.lastPieceRow !== null && this.lastPieceCol !== null) {
      this.draw_last_piece_border();
    }
  }

}