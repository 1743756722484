const AC_GAME_OBJECT = [];

export class GameObject {
  constructor() {
    AC_GAME_OBJECT.push(this);
    this.timedelta = 0;//用于记录每一帧距离上一帧执行的时间间隔
    this.has_called_start = false;//用于判断是否已经调用过start函数
  }

  //创建时只执行一次
  start() {

  }

  //除了创建时的第一帧，每一帧都会执行一次
  update() {

  }

  //删除之前执行
  on_destory() {

  }

  destroy() {
    this.on_destory();
    //let i in AC_GAME_OBJECT  i是索引
    //let i of AC_GAME_OBJECT  i是元素的值
    for (let i in AC_GAME_OBJECT) {
      if (AC_GAME_OBJECT[i] === this) {
        AC_GAME_OBJECT.splice(i);
        break;
      }
    }
  }
}

let last_timestamp;//上一次执行的时刻
const step = timestamp => {

  for (let obj of AC_GAME_OBJECT) {
    if (!obj.has_called_start) {
      obj.start();
      obj.has_called_start = true;
    } else {
      obj.timedelta = timestamp - last_timestamp;
      obj.update();
    }

  }
  last_timestamp = timestamp;
  requestAnimationFrame(step);
}

//这个requestAnimationFrame是浏览器提供的一个API
//用于在浏览器下一帧执行一次step函数
requestAnimationFrame(step);